// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { extractAuthor } from "./util.js";
export function createHeaderExtended(registry, header, validators) {
  // an instance of the base extrinsic for us to extend
  const HeaderBase = registry.createClass('Header');

  class Implementation extends HeaderBase {
    #author;
    #validators;

    constructor(registry, header, validators) {
      super(registry, header);
      this.#author = extractAuthor(this.digest, validators);
      this.#validators = validators;
      this.createdAtHash = header === null || header === void 0 ? void 0 : header.createdAtHash;
    }
    /**
     * @description Convenience method, returns the author for the block
     */


    get author() {
      return this.#author;
    }
    /**
     * @description Convenience method, returns the validators for the block
     */


    get validators() {
      return this.#validators;
    }

  }

  return new Implementation(registry, header, validators);
}