// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { BehaviorSubject, map, of, switchMap, tap, toArray } from 'rxjs';
import { memo } from "../util/index.js";

function traverseLinks(api, head) {
  const subject = new BehaviorSubject(head);
  return subject.pipe(switchMap(account => api.query.bagsList.listNodes(account)), tap(node => {
    setTimeout(() => {
      node.isSome && node.value.next.isSome ? subject.next(node.unwrap().next.unwrap()) : subject.complete();
    }, 0);
  }), toArray(), // toArray since we want to startSubject to be completed
  map(all => all.map(o => o.unwrap())));
}

export function listNodes(instanceId, api) {
  return memo(instanceId, bag => bag && bag.head.isSome ? traverseLinks(api, bag.head.unwrap()) : of([]));
}