export const render = function (token, idName) {
  const cardElement = document.getElementById(idName);

  const cardField = cardElement.querySelectorAll(".card__data__num");
  const footerField = cardElement.querySelectorAll(".card__footer-text");

  cardField[0].textContent = `${token.balance.toFixed(2)} ($${
    token.balanceUsd < 0 ? "N/A" : token.balanceUsd.toFixed(2)
  })`;

  cardField[1].textContent = `$${
    token.price < 0 ? "N/A" : token.price.toFixed(2)
  }`;

  cardField[2].textContent = token.onlinedate;
  footerField[0].textContent = `${
    token.get1D < 0 ? "N/A" : token.get1D.toFixed(2)
  }`;

  footerField[1].textContent = `${
    token.get7D < 0 ? "N/A" : token.get7D.toFixed(2)
  }`;
};

export const renderDig = function (token, idName, digNum) {
  const cardElement = document.getElementById(idName);

  const cardField = cardElement.querySelectorAll(".card__data__num");
  const footerField = cardElement.querySelectorAll(".card__footer-text");

  cardField[0].textContent = `${token.balance.toFixed(digNum)} ($${
    token.balanceUsd < 0 ? "N/A" : token.balanceUsd.toFixed(2)
  })`;

  cardField[1].textContent = `$${
    token.price < 0 ? "N/A" : token.price.toFixed(2)
  }`;

  cardField[2].textContent = token.onlinedate;
  footerField[0].textContent = `${
    token.get1D < 0 ? "N/A" : token.get1D.toFixed(digNum)
  }`;

  footerField[1].textContent = `${
    token.get7D < 0 ? "N/A" : token.get7D.toFixed(digNum)
  }`;
};

export const renderDigSpe = function (token, idName, digNum) {
  const cardElement = document.getElementById(idName);

  const cardField = cardElement.querySelectorAll(".card__data__num");
  const footerField = cardElement.querySelectorAll(".card__footer-text");

  cardField[0].textContent = `${token.balance.toFixed(digNum)} ($${
    token.balanceUsd < 0 ? "N/A" : token.balanceUsd.toFixed(2)
  })`;

  cardField[1].textContent = `$${
    token.price < 0 ? "N/A" : token.price.toFixed(2)
  }`;

  cardField[2].textContent = token.onlinedate;
  footerField[0].textContent = `${
    token.get1D < 0 ? "N/A" : token.get1D.toFixed(2)
  } USD`;

  footerField[1].textContent = `${
    token.get7D < 0 ? "N/A" : token.get7D.toFixed(2)
  } USD`;
};

export const showBalance = function (totalBalance) {
  const headElement = document.getElementById("total_balance");

  headElement.textContent = "$" + totalBalance.toFixed(2);
};

export const showProcess = function (info) {
  const headElement = document.getElementById("total_balance");

  headElement.textContent = info;
};
