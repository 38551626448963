// Copyright 2019-2022 @polkadot/wasm-bridge authors & contributors
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { stringToU8a, u8aToString } from '@polkadot/util';
import { Wbg } from "./wbg.js";
export class Bridge {
  #cachegetInt32;
  #cachegetUint8;
  #createWasm;
  #heap;
  #heapNext;
  #wasm;
  #wasmError;
  #wasmPromise;
  #wbg;
  #type;

  constructor(createWasm) {
    this.#createWasm = createWasm;
    this.#cachegetInt32 = null;
    this.#cachegetUint8 = null;
    this.#heap = new Array(32).fill(undefined).concat(undefined, null, true, false);
    this.#heapNext = this.#heap.length;
    this.#type = 'none';
    this.#wasm = null;
    this.#wasmError = null;
    this.#wasmPromise = null;
    this.#wbg = { ...new Wbg(this)
    };
  }

  get error() {
    return this.#wasmError;
  }

  get type() {
    return this.#type;
  }

  get wbg() {
    return this.#wbg;
  }

  get wasm() {
    return this.#wasm;
  }

  async init(createWasm) {
    if (!this.#wasmPromise || createWasm) {
      this.#wasmPromise = (createWasm || this.#createWasm)(this.#wbg);
    }

    const {
      error,
      type,
      wasm
    } = await this.#wasmPromise;
    this.#type = type;
    this.#wasm = wasm;
    this.#wasmError = error;
    return this.#wasm;
  }

  getObject(idx) {
    return this.#heap[idx];
  }

  dropObject(idx) {
    if (idx < 36) {
      return;
    }

    this.#heap[idx] = this.#heapNext;
    this.#heapNext = idx;
  }

  takeObject(idx) {
    const ret = this.getObject(idx);
    this.dropObject(idx);
    return ret;
  }

  addObject(obj) {
    if (this.#heapNext === this.#heap.length) {
      this.#heap.push(this.#heap.length + 1);
    }

    const idx = this.#heapNext;
    this.#heapNext = this.#heap[idx];
    this.#heap[idx] = obj;
    return idx;
  }

  getInt32() {
    if (this.#cachegetInt32 === null || this.#cachegetInt32.buffer !== this.#wasm.memory.buffer) {
      this.#cachegetInt32 = new Int32Array(this.#wasm.memory.buffer);
    }

    return this.#cachegetInt32;
  }

  getUint8() {
    if (this.#cachegetUint8 === null || this.#cachegetUint8.buffer !== this.#wasm.memory.buffer) {
      this.#cachegetUint8 = new Uint8Array(this.#wasm.memory.buffer);
    }

    return this.#cachegetUint8;
  }

  getU8a(ptr, len) {
    return this.getUint8().subarray(ptr / 1, ptr / 1 + len);
  }

  getString(ptr, len) {
    return u8aToString(this.getU8a(ptr, len));
  }

  allocU8a(arg) {
    const ptr = this.#wasm.__wbindgen_malloc(arg.length * 1);

    this.getUint8().set(arg, ptr / 1);
    return [ptr, arg.length];
  }

  allocString(arg) {
    return this.allocU8a(stringToU8a(arg));
  }

  resultU8a() {
    const r0 = this.getInt32()[8 / 4 + 0];
    const r1 = this.getInt32()[8 / 4 + 1];
    const ret = this.getU8a(r0, r1).slice();

    this.#wasm.__wbindgen_free(r0, r1 * 1);

    return ret;
  }

  resultString() {
    return u8aToString(this.resultU8a());
  }

}