// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { map, of } from 'rxjs';
import { firstMemo, memo } from "../util/index.js";
import { erasHistoricApplyAccount } from "./util.js";
export function _ownExposures(instanceId, api) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return memo(instanceId, (accountId, eras, _withActive) => eras.length ? api.queryMulti([...eras.map(e => [api.query.staking.erasStakersClipped, [e, accountId]]), ...eras.map(e => [api.query.staking.erasStakers, [e, accountId]])]).pipe(map(all => eras.map((era, index) => ({
    clipped: all[index],
    era,
    exposure: all[eras.length + index]
  })))) : of([]));
}
export const ownExposure = firstMemo((api, accountId, era) => api.derive.staking._ownExposures(accountId, [era], true));
export const ownExposures = erasHistoricApplyAccount('_ownExposures');