// Copyright 2017-2022 @polkadot/types-known authors & contributors
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable sort-keys */
import { mapXcmTypes } from '@polkadot/types-create';
import { objectSpread } from '@polkadot/util';
const sharedTypes = {
  TAssetBalance: 'u128',
  ProxyType: {
    _enum: ['Any', 'NonTransfer', 'CancelProxy', 'Assets', 'AssetOwner', 'AssetManager', 'Staking']
  }
}; // these are override types for Statemine, Statemint, Westmint

const versioned = [{
  minmax: [0, 3],
  types: objectSpread({}, sharedTypes, mapXcmTypes('V0'))
}, {
  minmax: [4, 5],
  types: objectSpread({}, sharedTypes, mapXcmTypes('V1'))
}, {
  // metadata V14
  minmax: [500, undefined],
  types: {}
}];
export default versioned;