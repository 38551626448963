// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { combineLatest, map, of, switchMap } from 'rxjs';
import { createSignedBlockExtended } from "../type/index.js";
import { memo } from "../util/index.js";
/**
 * @name subscribeNewBlocks
 * @returns The latest block & events for that block
 */

export function subscribeNewBlocks(instanceId, api) {
  return memo(instanceId, () => api.derive.chain.subscribeNewHeads().pipe(switchMap(header => {
    const blockHash = header.createdAtHash || header.hash; // we get the block first, setting up the registry

    return combineLatest([of(header), api.rpc.chain.getBlock(blockHash), api.queryAt(blockHash).pipe(switchMap(queryAt => queryAt.system.events()))]);
  }), map(([header, block, events]) => createSignedBlockExtended(block.registry, block, events, header.validators))));
}