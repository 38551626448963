// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { combineLatest, map, of } from 'rxjs';
import { memo } from "../util/index.js";

function parseFlags(address, [electionsMembers, councilMembers, technicalCommitteeMembers, societyMembers, sudoKey]) {
  const addrStr = address && address.toString();

  const isIncluded = id => id.toString() === addrStr;

  return {
    isCouncil: ((electionsMembers === null || electionsMembers === void 0 ? void 0 : electionsMembers.map(([id]) => id)) || councilMembers || []).some(isIncluded),
    isSociety: (societyMembers || []).some(isIncluded),
    isSudo: (sudoKey === null || sudoKey === void 0 ? void 0 : sudoKey.toString()) === addrStr,
    isTechCommittee: (technicalCommitteeMembers || []).some(isIncluded)
  };
}
/**
 * @name info
 * @description Returns account membership flags
 */


export function flags(instanceId, api) {
  return memo(instanceId, address => {
    var _api$query$council, _api$query$technicalC, _api$query$society, _api$query$sudo;

    const elections = api.query.phragmenElection || api.query.electionsPhragmen || api.query.elections;
    return combineLatest([address && elections !== null && elections !== void 0 && elections.members ? elections.members() : of(undefined), address && (_api$query$council = api.query.council) !== null && _api$query$council !== void 0 && _api$query$council.members ? api.query.council.members() : of([]), address && (_api$query$technicalC = api.query.technicalCommittee) !== null && _api$query$technicalC !== void 0 && _api$query$technicalC.members ? api.query.technicalCommittee.members() : of([]), address && (_api$query$society = api.query.society) !== null && _api$query$society !== void 0 && _api$query$society.members ? api.query.society.members() : of([]), address && (_api$query$sudo = api.query.sudo) !== null && _api$query$sudo !== void 0 && _api$query$sudo.key ? api.query.sudo.key() : of(undefined)]).pipe(map(r => parseFlags(address, r)));
  });
}