// Copyright (C) 2021-2022 Parity Technologies (UK) Ltd.
// SPDX-License-Identifier: Apache-2.0
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// 	http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export default [
	{
		"prefix": 0,
		"network": "polkadot",
		"displayName": "Polkadot Relay Chain",
		"symbols": [
			"DOT"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://polkadot.network"
	},
	{
		"prefix": 1,
		"network": "BareSr25519",
		"displayName": "Bare 32-bit Schnorr/Ristretto (S/R 25519) public key.",
		"symbols": [],
		"decimals": [],
		"standardAccount": "Sr25519",
		"website": null
	},
	{
		"prefix": 2,
		"network": "kusama",
		"displayName": "Kusama Relay Chain",
		"symbols": [
			"KSM"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://kusama.network"
	},
	{
		"prefix": 3,
		"network": "BareEd25519",
		"displayName": "Bare 32-bit Ed25519 public key.",
		"symbols": [],
		"decimals": [],
		"standardAccount": "Ed25519",
		"website": null
	},
	{
		"prefix": 4,
		"network": "katalchain",
		"displayName": "Katal Chain",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": null
	},
	{
		"prefix": 5,
		"network": "astar",
		"displayName": "Astar Network",
		"symbols": [
			"ASTR"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://astar.network"
	},
	{
		"prefix": 6,
		"network": "bifrost",
		"displayName": "Bifrost",
		"symbols": [
			"BNC"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://bifrost.finance/"
	},
	{
		"prefix": 7,
		"network": "edgeware",
		"displayName": "Edgeware",
		"symbols": [
			"EDG"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://edgewa.re"
	},
	{
		"prefix": 8,
		"network": "karura",
		"displayName": "Karura",
		"symbols": [
			"KAR"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://karura.network/"
	},
	{
		"prefix": 9,
		"network": "reynolds",
		"displayName": "Laminar Reynolds Canary",
		"symbols": [
			"REY"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "http://laminar.network/"
	},
	{
		"prefix": 10,
		"network": "acala",
		"displayName": "Acala",
		"symbols": [
			"ACA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://acala.network/"
	},
	{
		"prefix": 11,
		"network": "laminar",
		"displayName": "Laminar",
		"symbols": [
			"LAMI"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "http://laminar.network/"
	},
	{
		"prefix": 12,
		"network": "polymesh",
		"displayName": "Polymesh",
		"symbols": [
			"POLYX"
		],
		"decimals": [
			6
		],
		"standardAccount": "*25519",
		"website": "https://polymath.network/"
	},
	{
		"prefix": 13,
		"network": "integritee",
		"displayName": "Integritee",
		"symbols": [
			"TEER"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://integritee.network"
	},
	{
		"prefix": 14,
		"network": "totem",
		"displayName": "Totem",
		"symbols": [
			"TOTEM"
		],
		"decimals": [
			0
		],
		"standardAccount": "*25519",
		"website": "https://totemaccounting.com"
	},
	{
		"prefix": 15,
		"network": "synesthesia",
		"displayName": "Synesthesia",
		"symbols": [
			"SYN"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://synesthesia.network/"
	},
	{
		"prefix": 16,
		"network": "kulupu",
		"displayName": "Kulupu",
		"symbols": [
			"KLP"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://kulupu.network/"
	},
	{
		"prefix": 17,
		"network": "dark",
		"displayName": "Dark Mainnet",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": null
	},
	{
		"prefix": 18,
		"network": "darwinia",
		"displayName": "Darwinia Network",
		"symbols": [
			"RING",
			"KTON"
		],
		"decimals": [
			9,
			9
		],
		"standardAccount": "*25519",
		"website": "https://darwinia.network/"
	},
	{
		"prefix": 20,
		"network": "stafi",
		"displayName": "Stafi",
		"symbols": [
			"FIS"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://stafi.io"
	},
	{
		"prefix": 22,
		"network": "dock-pos-mainnet",
		"displayName": "Dock Mainnet",
		"symbols": [
			"DCK"
		],
		"decimals": [
			6
		],
		"standardAccount": "*25519",
		"website": "https://dock.io"
	},
	{
		"prefix": 23,
		"network": "shift",
		"displayName": "ShiftNrg",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": null
	},
	{
		"prefix": 24,
		"network": "zero",
		"displayName": "ZERO",
		"symbols": [
			"ZERO"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://zero.io"
	},
	{
		"prefix": 25,
		"network": "zero-alphaville",
		"displayName": "ZERO Alphaville",
		"symbols": [
			"ZERO"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://zero.io"
	},
	{
		"prefix": 26,
		"network": "jupiter",
		"displayName": "Jupiter",
		"symbols": [
			"jDOT"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://jupiter.patract.io"
	},
	{
		"prefix": 27,
		"network": "kabocha",
		"displayName": "Kabocha",
		"symbols": [
			"KAB"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://kabocha.network"
	},
	{
		"prefix": 28,
		"network": "subsocial",
		"displayName": "Subsocial",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": null
	},
	{
		"prefix": 29,
		"network": "cord",
		"displayName": "CORD Network",
		"symbols": [
			"DHI",
			"WAY"
		],
		"decimals": [
			12,
			12
		],
		"standardAccount": "*25519",
		"website": "https://cord.network/"
	},
	{
		"prefix": 30,
		"network": "phala",
		"displayName": "Phala Network",
		"symbols": [
			"PHA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://phala.network"
	},
	{
		"prefix": 31,
		"network": "litentry",
		"displayName": "Litentry Network",
		"symbols": [
			"LIT"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://litentry.com/"
	},
	{
		"prefix": 32,
		"network": "robonomics",
		"displayName": "Robonomics",
		"symbols": [
			"XRT"
		],
		"decimals": [
			9
		],
		"standardAccount": "*25519",
		"website": "https://robonomics.network"
	},
	{
		"prefix": 33,
		"network": "datahighway",
		"displayName": "DataHighway",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": null
	},
	{
		"prefix": 34,
		"network": "ares",
		"displayName": "Ares Protocol",
		"symbols": [
			"ARES"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://www.aresprotocol.com/"
	},
	{
		"prefix": 35,
		"network": "vln",
		"displayName": "Valiu Liquidity Network",
		"symbols": [
			"USDv"
		],
		"decimals": [
			15
		],
		"standardAccount": "*25519",
		"website": "https://valiu.com/"
	},
	{
		"prefix": 36,
		"network": "centrifuge",
		"displayName": "Centrifuge Chain",
		"symbols": [
			"CFG"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://centrifuge.io/"
	},
	{
		"prefix": 37,
		"network": "nodle",
		"displayName": "Nodle Chain",
		"symbols": [
			"NODL"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://nodle.io/"
	},
	{
		"prefix": 38,
		"network": "kilt",
		"displayName": "KILT Spiritnet",
		"symbols": [
			"KILT"
		],
		"decimals": [
			15
		],
		"standardAccount": "*25519",
		"website": "https://kilt.io/"
	},
	{
		"prefix": 39,
		"network": "mathchain",
		"displayName": "MathChain mainnet",
		"symbols": [
			"MATH"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://mathwallet.org"
	},
	{
		"prefix": 40,
		"network": "mathchain-testnet",
		"displayName": "MathChain testnet",
		"symbols": [
			"MATH"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://mathwallet.org"
	},
	{
		"prefix": 41,
		"network": "poli",
		"displayName": "Polimec Chain",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": "https://polimec.io/"
	},
	{
		"prefix": 42,
		"network": "substrate",
		"displayName": "Substrate",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": "https://substrate.io/"
	},
	{
		"prefix": 43,
		"network": "BareSecp256k1",
		"displayName": "Bare 32-bit ECDSA SECP-256k1 public key.",
		"symbols": [],
		"decimals": [],
		"standardAccount": "secp256k1",
		"website": null
	},
	{
		"prefix": 44,
		"network": "chainx",
		"displayName": "ChainX",
		"symbols": [
			"PCX"
		],
		"decimals": [
			8
		],
		"standardAccount": "*25519",
		"website": "https://chainx.org/"
	},
	{
		"prefix": 45,
		"network": "uniarts",
		"displayName": "UniArts Network",
		"symbols": [
			"UART",
			"UINK"
		],
		"decimals": [
			12,
			12
		],
		"standardAccount": "*25519",
		"website": "https://uniarts.me"
	},
	{
		"prefix": 46,
		"network": "reserved46",
		"displayName": "This prefix is reserved.",
		"symbols": [],
		"decimals": [],
		"standardAccount": null,
		"website": null
	},
	{
		"prefix": 47,
		"network": "reserved47",
		"displayName": "This prefix is reserved.",
		"symbols": [],
		"decimals": [],
		"standardAccount": null,
		"website": null
	},
	{
		"prefix": 48,
		"network": "neatcoin",
		"displayName": "Neatcoin Mainnet",
		"symbols": [
			"NEAT"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://neatcoin.org"
	},
	{
		"prefix": 49,
		"network": "picasso",
		"displayName": "Picasso",
		"symbols": [
			"PICA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://picasso.composable.finance"
	},
	{
		"prefix": 50,
		"network": "composable",
		"displayName": "Composable",
		"symbols": [
			"LAYR"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://composable.finance"
	},
	{
		"prefix": 51,
		"network": "oak",
		"displayName": "OAK Network",
		"symbols": [
			"OAK"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://oak.tech"
	},
	{
		"prefix": 52,
		"network": "KICO",
		"displayName": "KICO",
		"symbols": [
			"KICO"
		],
		"decimals": [
			14
		],
		"standardAccount": "*25519",
		"website": "https://dico.io"
	},
	{
		"prefix": 53,
		"network": "DICO",
		"displayName": "DICO",
		"symbols": [
			"DICO"
		],
		"decimals": [
			14
		],
		"standardAccount": "*25519",
		"website": "https://dico.io"
	},
	{
		"prefix": 54,
		"network": "cere",
		"displayName": "Cere Network",
		"symbols": [
			"CERE"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://cere.network"
	},
	{
		"prefix": 55,
		"network": "xxnetwork",
		"displayName": "xx network",
		"symbols": [
			"XX"
		],
		"decimals": [
			9
		],
		"standardAccount": "*25519",
		"website": "https://xx.network"
	},
	{
		"prefix": 63,
		"network": "hydradx",
		"displayName": "HydraDX",
		"symbols": [
			"HDX"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://hydradx.io"
	},
	{
		"prefix": 65,
		"network": "aventus",
		"displayName": "AvN Mainnet",
		"symbols": [
			"AVT"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://aventus.io"
	},
	{
		"prefix": 66,
		"network": "crust",
		"displayName": "Crust Network",
		"symbols": [
			"CRU"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://crust.network"
	},
	{
		"prefix": 67,
		"network": "genshiro",
		"displayName": "Genshiro Network",
		"symbols": [
			"GENS",
			"EQD",
			"LPT0"
		],
		"decimals": [
			9,
			9,
			9
		],
		"standardAccount": "*25519",
		"website": "https://genshiro.equilibrium.io"
	},
	{
		"prefix": 68,
		"network": "equilibrium",
		"displayName": "Equilibrium Network",
		"symbols": [
			"EQ"
		],
		"decimals": [
			9
		],
		"standardAccount": "*25519",
		"website": "https://equilibrium.io"
	},
	{
		"prefix": 69,
		"network": "sora",
		"displayName": "SORA Network",
		"symbols": [
			"XOR"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://sora.org"
	},
	{
		"prefix": 73,
		"network": "zeitgeist",
		"displayName": "Zeitgeist",
		"symbols": [
			"ZTG"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://zeitgeist.pm"
	},
	{
		"prefix": 77,
		"network": "manta",
		"displayName": "Manta network",
		"symbols": [
			"MANTA"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://manta.network"
	},
	{
		"prefix": 78,
		"network": "calamari",
		"displayName": "Calamari: Manta Canary Network",
		"symbols": [
			"KMA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://manta.network"
	},
	{
		"prefix": 88,
		"network": "polkadex",
		"displayName": "Polkadex Mainnet",
		"symbols": [
			"PDEX"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://polkadex.trade"
	},
	{
		"prefix": 89,
		"network": "polkadexparachain",
		"displayName": "Polkadex Parachain",
		"symbols": [
			"PDEX"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://polkadex.trade"
	},
	{
		"prefix": 93,
		"network": "fragnova",
		"displayName": "Fragnova Network",
		"symbols": [
			"NOVA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://fragnova.com"
	},
	{
		"prefix": 98,
		"network": "polkasmith",
		"displayName": "PolkaSmith Canary Network",
		"symbols": [
			"PKS"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://polkafoundry.com"
	},
	{
		"prefix": 99,
		"network": "polkafoundry",
		"displayName": "PolkaFoundry Network",
		"symbols": [
			"PKF"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://polkafoundry.com"
	},
	{
		"prefix": 101,
		"network": "origintrail-parachain",
		"displayName": "OriginTrail Parachain",
		"symbols": [
			"OTP"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://parachain.origintrail.io/"
	},
	{
		"prefix": 105,
		"network": "pontem-network",
		"displayName": "Pontem Network",
		"symbols": [
			"PONT"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://pontem.network"
	},
	{
		"prefix": 110,
		"network": "heiko",
		"displayName": "Heiko",
		"symbols": [
			"HKO"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://parallel.fi/"
	},
	{
		"prefix": 113,
		"network": "integritee-incognito",
		"displayName": "Integritee Incognito",
		"symbols": [],
		"decimals": [],
		"standardAccount": "*25519",
		"website": "https://integritee.network"
	},
	{
		"prefix": 117,
		"network": "tinker",
		"displayName": "Tinker",
		"symbols": [
			"TNKR"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://invarch.network"
	},
	{
		"prefix": 128,
		"network": "clover",
		"displayName": "Clover Finance",
		"symbols": [
			"CLV"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://clover.finance"
	},
	{
		"prefix": 131,
		"network": "litmus",
		"displayName": "Litmus Network",
		"symbols": [
			"LIT"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://litentry.com/"
	},
	{
		"prefix": 136,
		"network": "altair",
		"displayName": "Altair",
		"symbols": [
			"AIR"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://centrifuge.io/"
	},
	{
		"prefix": 172,
		"network": "parallel",
		"displayName": "Parallel",
		"symbols": [
			"PARA"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://parallel.fi/"
	},
	{
		"prefix": 252,
		"network": "social-network",
		"displayName": "Social Network",
		"symbols": [
			"NET"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://social.network"
	},
	{
		"prefix": 255,
		"network": "quartz_mainnet",
		"displayName": "QUARTZ by UNIQUE",
		"symbols": [
			"QTZ"
		],
		"decimals": [
			15
		],
		"standardAccount": "*25519",
		"website": "https://unique.network"
	},
	{
		"prefix": 268,
		"network": "pioneer_network",
		"displayName": "Pioneer Network by Bit.Country",
		"symbols": [
			"NEER"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://bit.country"
	},
	{
		"prefix": 420,
		"network": "sora_kusama_para",
		"displayName": "SORA Kusama Parachain",
		"symbols": [
			"XOR"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://sora.org"
	},
	{
		"prefix": 789,
		"network": "geek",
		"displayName": "GEEK Network",
		"symbols": [
			"GEEK"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://geek.gl"
	},
	{
		"prefix": 1110,
		"network": "efinity",
		"displayName": "Efinity",
		"symbols": [
			"EFI"
		],
		"decimals": [
			18
		],
		"standardAccount": "Sr25519",
		"website": "https://efinity.io/"
	},
	{
		"prefix": 1284,
		"network": "moonbeam",
		"displayName": "Moonbeam",
		"symbols": [
			"GLMR"
		],
		"decimals": [
			18
		],
		"standardAccount": "secp256k1",
		"website": "https://moonbeam.network"
	},
	{
		"prefix": 1285,
		"network": "moonriver",
		"displayName": "Moonriver",
		"symbols": [
			"MOVR"
		],
		"decimals": [
			18
		],
		"standardAccount": "secp256k1",
		"website": "https://moonbeam.network"
	},
	{
		"prefix": 1328,
		"network": "ajuna",
		"displayName": "Ajuna Network",
		"symbols": [
			"AJUN"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://ajuna.io"
	},
	{
		"prefix": 1337,
		"network": "bajun",
		"displayName": "Bajun Network",
		"symbols": [
			"BAJU"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://ajuna.io"
	},
	{
		"prefix": 2007,
		"network": "kapex",
		"displayName": "Kapex",
		"symbols": [
			"KAPEX"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://totemaccounting.com"
	},
	{
		"prefix": 2032,
		"network": "interlay",
		"displayName": "Interlay",
		"symbols": [
			"INTR"
		],
		"decimals": [
			10
		],
		"standardAccount": "*25519",
		"website": "https://interlay.io/"
	},
	{
		"prefix": 2092,
		"network": "kintsugi",
		"displayName": "Kintsugi",
		"symbols": [
			"KINT"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://interlay.io/"
	},
	{
		"prefix": 2254,
		"network": "subspace_testnet",
		"displayName": "Subspace testnet",
		"symbols": [
			"tSSC"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://subspace.network"
	},
	{
		"prefix": 6094,
		"network": "subspace",
		"displayName": "Subspace",
		"symbols": [
			"SSC"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://subspace.network"
	},
	{
		"prefix": 7007,
		"network": "tidefi",
		"displayName": "Tidefi",
		"symbols": [
			"TIFI"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://tidefi.com"
	},
	{
		"prefix": 7391,
		"network": "unique_mainnet",
		"displayName": "Unique Network",
		"symbols": [
			"UNQ"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://unique.network"
	},
	{
		"prefix": 9807,
		"network": "dentnet",
		"displayName": "DENTNet",
		"symbols": [
			"DENTX"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://www.dentnet.io"
	},
	{
		"prefix": 10041,
		"network": "basilisk",
		"displayName": "Basilisk",
		"symbols": [
			"BSX"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://bsx.fi"
	},
	{
		"prefix": 11330,
		"network": "cess-testnet",
		"displayName": "CESS Testnet",
		"symbols": [
			"TCESS"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://cess.cloud"
	},
	{
		"prefix": 11331,
		"network": "cess",
		"displayName": "CESS",
		"symbols": [
			"CESS"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://cess.cloud"
	},
	{
		"prefix": 11820,
		"network": "contextfree",
		"displayName": "Automata ContextFree",
		"symbols": [
			"CTX"
		],
		"decimals": [
			18
		],
		"standardAccount": "*25519",
		"website": "https://ata.network"
	},
	{
		"prefix": 12191,
		"network": "nftmart",
		"displayName": "NFTMart",
		"symbols": [
			"NMT"
		],
		"decimals": [
			12
		],
		"standardAccount": "*25519",
		"website": "https://nftmart.io"
	}
];
