// Copyright 2017-2022 @polkadot/api-derive authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { map, of, switchMap } from 'rxjs';
import { BN_ZERO, bnToBn } from '@polkadot/util';
import { memo } from "../util/index.js";

function orderBags(ids, bags) {
  const sorted = ids.map((id, index) => ({
    bag: bags[index].unwrapOr(null),
    id,
    key: id.toString()
  })).sort((a, b) => b.id.cmp(a.id)).map((base, index) => ({ ...base,
    bagLower: BN_ZERO,
    bagUpper: base.id,
    index
  }));
  const max = sorted.length - 1;
  return sorted.map((entry, index) => index === max ? entry // We could probably use a .add(BN_ONE) here
  : { ...entry,
    bagLower: sorted[index + 1].bagUpper
  });
}

export function _getIds(instanceId, api) {
  return memo(instanceId, _ids => {
    const ids = _ids.map(id => bnToBn(id));

    return ids.length ? api.query.listBags.multi(ids).pipe(map(bags => orderBags(ids, bags))) : of([]);
  });
}
export function all(instanceId, api) {
  return memo(instanceId, () => api.query.bagsList.listBags.keys().pipe(switchMap(keys => api.derive.bagsList._getIds(keys.map(({
    args: [id]
  }) => id))), map(list => list.filter(({
    bag
  }) => bag))));
}
export function get(instanceId, api) {
  return memo(instanceId, id => api.derive.bagsList._getIds([bnToBn(id)]).pipe(map(bags => bags[0])));
}